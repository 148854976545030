.newsletter {
  width: 35rem;
  height: 20rem;
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > div:last-child {
      display: flex;
      align-items: center;
      input {
        margin-right: .5rem;
      }
      span {
        width: 2rem;
        svg {
          fill: #0E4D9F !important;
        }
      }
    }
  }
}
