@use 'globals';

.App {
  height: 100vh;
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 60px 60px 1fr;
  grid-template-areas:
          "sidebar navigation"
          "context context"
          "pages pages";
  align-items: stretch;
  color: globals.$navy;

  &.ctx-manager, &.ctx-rbb_dispatcher.c2o {
    grid-template-areas:
            "sidebar navigation"
            "sidebar context"
            "sidebar pages";

    &.authenticated {
      background: linear-gradient(to right, globals.$navy 400px, #FFFFFF 400px);
    }
  }

  span.loader {
    display: inline-block;
    $size: 8px;
    font-size: $size;
    width: $size*4;
    height: $size;
    vertical-align: middle;
    line-height: 1;

    i {
      display: inline-block;
      width: $size;
      height: $size;
      background-color: currentColor;
      animation: fadeOutIn .5s infinite alternate;

      & + i {
        margin-left: calc($size / 2);
      }

      &:nth-child(1) {
        animation-delay: .25s;
      }

      &:nth-child(2) {
        animation-delay: .5s;
      }

      &:nth-child(3) {
        animation-delay: .75s;
      }
    }
  }

  @keyframes fadeOutIn {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  h1, h2, h3, h4 {
    margin: 0;
  }

  input:not([type="radio"]):not([type="checkbox"]), select, textarea, button, .button {
    &:not(.react-datepicker__navigation) {
      font: inherit;
      background-color: #F7FAFF;
      border: 1px solid #D0D3D9;
      border-radius: 5px;
      width: 100%;
      padding: 8px;
      display: inline-block;

      &::placeholder {
        font: inherit;
        color: inherit;
        opacity: .5;
      }

      &:-webkit-autofill {
        &, &:hover, &:focus, &:active {
          //background-color: inherit !important;
          box-shadow: 0 0 100px #EAECEF inset;
        }
      }

      &:disabled {
        opacity: .6;
        cursor: not-allowed;
      }

      &:focus {
        box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
        outline: none;
      }
    }
  }

  input:not([type="radio"]):not([type="checkbox"]), select, textarea {
    &:read-only {
      opacity: .6;
    }
  }

  .react-select__control {
    background-color: #F7FAFF;
  }

  .react-select__menu {
    z-index: 1000;
  }

  button, .button {
    &:not(.react-datepicker__navigation) {
      box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
      border-color: #D0D3D9;
      background-color: #F7FAFF;
      min-width: 130px;
      width: auto;
      text-align: center;
      cursor: pointer;
      font-weight: 400;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      overflow: hidden;
      position: relative;

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: currentColor;
        opacity: 0;
        transition: .16s opacity;
      }

      &:hover:before {
        opacity: .1;
      }

      svg {
        margin-left: .7em;
        width: 16px;

        &:only-child {
          margin-left: 0;
        }
      }

      &.blue {
        background-color: globals.$blue;
        border-color: globals.$blue;
        color: #fff;
      }

      &.green {
        border-color: globals.$green;
        background-color: globals.$green;
        color: #fff;
      }

      &.yellow {
        border-color: globals.$yellow;
        background-color: globals.$yellow;
        color: #000;
      }

      &.red {
        border-color: #FF6B67;
        background-color: #FF6B67;
        color: #fff;
      }

      &.transparent {
        position: relative;
        background-color: transparent;
        border-color: currentColor;
        color: currentColor;
      }

      &:focus {
        outline: none;
      }

      &.small {
        padding: .1em .6em;
      }

      &.icon {
        min-width: initial;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 16px;
          vertical-align: middle;
        }
      }

      & + button, & + .button {
        margin-left: 10px;
      }
    }

    &.text-only {
      svg {
        width: 16px;
      }

      border: none;
      box-shadow: none;
      padding: 0;
      background: none;
      min-width: min-content;
      font-weight: 700;

      &:hover {
        background: none;
        text-decoration: underline;

        &:before {
          display: none;
        }
      }
    }
  }

  input[type="search"] {
    padding-left: 2.5em !important;
    background-image: url(./img/icon-search.png);
    background-position: .7em center;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    appearance: none;

    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  .field {
    margin: 1em 0;
    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-gap: 0 1em;
    align-items: center;

    .hint {
      grid-column: 2;
      padding-top: .3em;
      font-size: .8em;
    }

    &.password {
      position: relative;

      span.show-password {
        position: absolute;
        right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 1.2rem;
          color: globals.$blue;
        }

        &:hover {
          svg {
            scale: 1.1;
          }
        }
      }
    }

    &.checkbox {
      grid-template-columns: 1fr min-content;
    }

    .react-datepicker-wrapper {
      width: 100%;

      input {
        width: 100%;
        box-sizing: border-box;
      }

    }

    select, input:not([type="radio"]):not([type="checkbox"]) {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      border: 1px solid #D0D3D9;
      //background-color: #EAECEF;
      border-radius: 5px;

      margin: 0;
      padding: .5em .7em;
      font-weight: 400;
      font-size: inherit;

      text-align: start;

      &:invalid {
        border-color: globals.$red;
        box-shadow: 0 0 4px globals.$red;
      }

      &::placeholder {
        font-weight: 400;
      }
    }

    .react-select {
      border-radius: 5px;
    }

    select {
      background: {
        image: url("img/arrow_down.svg");
        position: right 6px center;
        repeat: no-repeat;
      };
      direction: rtl;

      option {
        direction: ltr;

        &:disabled {
          color: #666666;
        }
      }
    }

    &.error {
      > .react-select, > select, > input, > textarea, .react-datepicker-wrapper input {
        border-color: globals.$red;
        box-shadow: 0 0 4px globals.$red;
      }

      .error {
        padding-top: .3em;
        font-size: .8em;
        grid-column: 2;
        color: globals.$red;
        text-align: left;
      }
    }

    &.buttons {
      display: block;
      text-align: right;
    }

    &.required {
      label {
        &:after {
          font-weight: 700;
          content: ' *';
        }
      }
    }

    &.checkbox-container {
      grid-template-columns: auto 1fr !important;
      cursor: pointer;

      svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
      }

      &.error {
        svg {
          border: 1px solid globals.$red;
          border-radius: 10px;
          box-shadow: 0 0 4px globals.$red;
        }
      }

      &.disabled {
        cursor: not-allowed;

        svg {
          opacity: .5;
        }
      }

      &.without-label {
        grid-template-columns: auto !important;
      }
    }
  }

  a, .link {
    color: globals.$navy;
    text-decoration: none;
    cursor: pointer;
  }

  img.lazy {
    opacity: 0;
    transition: 1s opacity;

    &.loaded {
      opacity: 1;
    }
  }

  img.bg {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  .icon {
    display: inline-block;
    margin: .4em;
    width: 1em;
    height: 1em;
    padding: 0;
    color: #2a87d0;
    position: relative;
    vertical-align: middle;
  }

  .modal {
    font-size: 1rem;
    border-radius: 10px;
    background-color: #FFFFFF;
    overflow: hidden;

    position: relative;
    color: #1D2135;

    header, main, footer {
      padding: 1.2em;
    }

    header {
      font-weight: 600;
      text-align: center;
      border-bottom: 1px solid #EFEFEF;
    }

    footer {
      background-color: #EFEFEF;
      text-align: right;
    }

    &.delete, &.floating {
      position: fixed;
      width: 350px;
      height: 250px;
      top: calc(50% - 175px);
      left: calc(50% - 125px);
      transform: scale(0);
      z-index: 1000;
      filter: none !important;
      box-shadow: 0 5px 20px rgba(0, 0, 0, .7);
      transition: transform .16s;

      &.visible {
        transform: scale(1);
      }

      display: flex;
      flex-direction: column;

      header, footer {
        flex: 0 0 auto;
      }

      main {
        flex: 1 1 auto;
        overflow: auto;
      }
    }

    &.floating {
      width: 500px;
      height: 400px;
      top: calc(50% - 200px);
      left: calc(50% - 250px);
    }
  }

  #changeLog {
    width: calc(100% - 100px);
    height: calc(100% - 160px);
    top: 110px;
    left: 50px;
    z-index: 1003;
  }


  #sidebar {
    grid-area: sidebar;
  }

  #navigation {
    grid-area: navigation;
  }

  #pageHeader {
    grid-area: context;
    background-color: #fff;
    border-bottom: 2px solid rgba(0, 0, 0, .2);
    font-weight: 700;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 .7em;

    .group {
      margin-right: auto;
    }

    .tabSelector {
      margin-right: auto;

      button {
        cursor: pointer;
        margin: 0;

        &:not(:last-of-type) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
        }

        &:not(:first-of-type) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0;
        }

        &.active {
          color: #fff;
          background-color: #0E4D9F;
        }
      }
    }

    .search {
      margin: 0 .7em;
    }
  }

  > main {
    grid-area: pages;
    background-color: #fff;

    position: relative;
    overflow: auto;

    table {
      width: 100%;
      border-collapse: separate; /* Don't collapse */
      border-spacing: 0;

      position: relative;

      thead {
        td, th {
          position: sticky;
          top: 0;
          border: solid #D3D6DB;
          border-width: 0 0 2px 0;
          background-color: #F7F7F7;
          text-align: left;

          &[data-sorted] {
            user-select: none;
            $size: .5em;
            padding-right: .5em + $size*2;
            position: relative;

            &:after {
              content: '';
              display: block;
              width: 0;
              height: 0;
              position: absolute;
              top: 50%;
              right: .5em;
              border: solid transparent;
              border-width: $size calc($size / 2);
            }

            &[data-sorted="asc" i]:after {
              top: calc(50% - (#{$size} / 2));
              border-color: currentColor transparent transparent transparent;
            }

            &[data-sorted="desc" i]:after {
              top: calc(50% - (#{$size} * 1.5));
              border-color: transparent transparent currentColor transparent;
            }
          }
        }
      }

      td, th {
        height: 60px;
        padding: .5em;
        display: flex;
        align-items: center;

        &.noData {
          justify-content: center;
        }

        &.has-checkbox:first-child {
          width: 1%;
          max-width: 40px !important;
          min-width: 40px !important;
        }

        .info {
          display: block;
          font-size: .75em;
          font-style: italic;
          color: gray;
        }

        input[type="checkbox"] {
          display: block;
          width: 16px;
          height: 16px;
          position: relative;
          accent-color: globals.$navy;
        }
      }

      td.noData {
        text-align: center;
      }

      tbody {
        tr.dimmed td {
          opacity: .6;
        }

        tr.invite td {
          color: globals.$blue;
        }

        tr[clickable] td {
          cursor: pointer;
        }

        tr:nth-child(2n) {
          td {
            background-color: #F7F7F7;
          }
        }

        tr.selected td {
          background-color: #2a87d0;
          color: #FFF;
        }

        time.expired {
          color: #FF6B67;
        }
      }
    }
  }

  .leaflet-marker-icon {
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, .3));
  }

  .map-pin {
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, .7));
  }

  .labeled-map-pin {
    position: relative;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, .7));

    .label {
      display: block;
      position: absolute;
      bottom: 55px;
      left: 0;
      color: black;
      white-space: nowrap;
      width: min-content;
      transform: translateX(-50%);
      font-size: 1.4em;
      line-height: 1;
      padding: .1em;
      border-radius: 3px;
      border: 1px solid black;
      background: white;
    }

    .bg {
      display: block;
      position: absolute;
      bottom: 0;
      left: -18px;
      width: 36px;
      height: 51px;
      background: url("img/pin_fill_sharp_circle.png") center / contain no-repeat;
    }
  }

  .rbb-labeled-map-pin {
    position: relative;

    &:hover {
      .label:not(.active) {
        background-color: #f3f3f3;

        &::after {
          opacity: 1;
        }
      }
    }

    .label {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 1rem;
      position: absolute;
      width: 12rem;
      bottom: 39.5px;
      left: 50%;
      color: #000;
      background-color: #fff;
      white-space: nowrap;
      transform: translateX(-50%);
      font-size: 1.4em;
      line-height: 1;
      border-radius: 5px;
      border: 1px solid #E8EAED;
      padding: .4rem 1.2rem;
      z-index: 2;
      box-shadow: 0 4px 4px #0000001A;

      p {
        margin: 0;
      }

      &::after {
        content: '';
        position: absolute;
        top: -1rem;
        left: 50%;
        width: 11px;
        height: 11px;
        opacity: 0;
        transform: translateX(-50%) matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
        border-top: 1px solid black;
        border-right: 1px solid black;
        transition: opacity .3s ease-out;
      }

      &.active {
        border-radius: 0 0 5px 5px;

        &::after {
          opacity: 0;
        }
      }
    }

    & > svg {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 25px;
      height: 52px;
      z-index: 1;
    }
  }

  .leaflet-popup {
    width: 12rem;
    bottom: 100px !important;

    .leaflet-popup-content-wrapper {
      border-radius: 5px 5px 0 0;
      box-shadow: none;
      overflow: hidden;
      margin: 0 auto;

      .leaflet-popup-content {
        width: auto !important;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .4rem;
        overflow: hidden;
        margin: 0;

        & > p {
          opacity: .33;
          margin: 0;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 5rem !important;
          min-width: 5rem !important;
          padding: 0 .4rem;
          border-color: transparent;
          box-shadow: none;
          color: #4EA1FF;
          background-color: transparent;
          transition: background-color .3s ease-out;

          svg {
            align-self: center;
            margin: 0;
          }

          p {
            margin: 0;
            align-self: center;
          }

          &:hover {
            background-color: #4EA1FF;
            color: #fff;

            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
      }
    }

    .leaflet-popup-close-button {
      display: none;
    }

    .leaflet-popup-tip-container {
      display: none;
    }
  }

  .leaflet-fade-anim {
    .leaflet-popup {
      transition: opacity .3s ease-out;
    }
  }

  .react-datepicker-popper {
    z-index: 1000 !important;
  }

  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;

    .group {
      border-bottom: 2px solid rgba(0, 0, 0, .2);
      padding-bottom: 1em;

      .title {
        display: grid;
        grid-template-columns: 36px 1fr;
        grid-gap: 2em;
        align-items: center;
        font-weight: 600;
        font-size: 1.25em;
        border-bottom: 1px solid rgba(0, 0, 0, .05);
        padding: 1rem 0;

        > svg {
          width: 36px;
          height: 36px;
          color: globals.$blue;

          path {
            stroke-width: 1.25;
          }
        }
      }
    }
  }

  .circle-pin {
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #387AC4;
  }
}


