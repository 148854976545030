@use '../../globals';

.RightPane {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  filter: none !important;
  overflow: hidden;
  .coat {
    position: absolute;
    z-index: 10;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 400px;
    //background-color: rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, .55);
    opacity: 0;
    pointer-events: none;
    transition: .4s opacity;
  }
  .window {
    position: absolute;
    z-index: 11;
    top: 60px;
    right: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);

    min-width: 280px;
    width: 100%;
    max-width: 560px;
    &.wide {
      max-width: 768px;
    }


    pointer-events: none;
    opacity: 0;
    transform: translateX(100%);
    transition-duration: .4s;
    transition-property: opacity, transform;


    display: flex;
    flex-direction: column;

    > .header {
      flex: 0 0 auto;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(37, 53, 77, 0.3);
      padding-right: 1rem;

      .close {
        flex: 0 0;
        padding: 1rem;
        font-size: 1.4em;
        color: globals.$blue-text;
        cursor: pointer;
      }

      .title {
        text-align: center;
        flex: 1 1 auto;
        color: globals.$blue-text;
        font-weight: 700;
        font-size: 24px;
      }
    }
    > .body {
      flex: 1 1 auto;
      overflow-y: scroll;
      padding: 0 2em 2em;
      box-shadow: inset 0 -30px 30px -30px rgba(0, 0, 0, 0.3);
      > nav {
        margin-left: -2em;
        margin-right: -2em;
        display: flex;
        //width: 100%;
        justify-content: stretch;
        a {
          display: inline-flex;
          height: 50px;
          align-items: center;
          justify-content: center;
          flex: 1;
          font-weight: 700;
          color: rgba(37, 53, 77, .5);
          background-color: #F7F7F7;
          &.active {
            background-color: #FFF;
            color: #000;
          }
        }
      }
    }
    > .footer {
      flex: 0 0 auto;
      height: 50px;
      background-color: #fff;
      padding: 0 1em;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .group {
      position: relative;
      > .title {
        font-weight: 600;
        width: 100%;
        font-size: 1.2em;

        > .icon {
          cursor: pointer;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      padding: 2em 0;

      &.hint {
        display: grid;
        grid-template-columns: 30px 1fr;
        grid-gap: 10px;
        .icon {
          margin-right: 1em;
        }
        .text {
          padding: .2em;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: -2em;
        bottom: 0;
        width: calc(100% + 4em);
        border-bottom: 1px solid rgba(37, 53, 77, 0.11);
      }

      &:last-child:after {
        display: none;
      }

      &.disabled {
        pointer-events: none;
        opacity: .2;
        input, select, textarea, button {
          &:disabled {
            opacity: 1;
          }
        }
      }
    }
    dl {
      display: grid;
      grid-template-columns: 4fr 6fr;
      grid-gap: 1em;
      width: 100%;

      dt {
        white-space: nowrap;
      }

      dd {
        font-weight: 600;
        margin-inline-start: 0;
      }
    }
  }

  ~ * {
    will-change: filter;
    transition: filter .4s;
  }
  &.visible {
    .coat {
      opacity: 1;
      pointer-events: auto;
    }
    .window {
      pointer-events: auto;
      opacity: 1;
      transform: translateX(0);
    }
    //~ * {
    //  filter: blur(2px);
    //}
  }
  .body {
    .errors {
      color: globals.$red;
    }


    .loader + * {
      opacity: .3;
      pointer-events: none;
    }
  }
}
